<template>
  <div>sadfasdf</div>
  <div id="demo"></div>
</template>

<script setup>
import Pdfh5 from 'pdfh5';
import { onMounted } from 'vue';

onMounted(() => {
  new Pdfh5('#demo', {
    pdfurl: "https://github.2333haha.com/http://www.pwithe.com/Public/Upload/download/20170211/589ebf8e5bb13.pdf"
  });
});
</script>


<style>
@import "pdfh5/css/pdfh5.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#demo {
  width: 800px;
  height: 800px;
}

* {
  padding: 0;
  margin: 0;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
